import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
//router
import { BrowserRouter } from "react-router-dom";
//store
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading1 from "./components/custom/loading1";

//reducer
import { store } from "./store";

import "./i18n";

ReactDOM.render(
  <StrictMode>
    <BrowserRouter basename="/">
      <Provider store={store}>
        <Suspense fallback={<Loading1/>}>
          <App />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Suspense>
      </Provider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);

